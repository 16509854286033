// Blog.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton, // TwitterShareButton for X functionality
  LinkedinShareButton,
  EmailShareButton
} from 'react-share';
import { FaFacebook, FaWhatsapp, FaLinkedin, FaEnvelope } from 'react-icons/fa'; // Import other icons from react-icons/fa
import { XIcon } from 'react-share'; // Importing XIcon for the X platform logo
import '../index.css'; // Ensure you have an index.css file for global styles

function Blog() {
  const currentUrl = window.location.href;

  return (
    <div className="blog-container">
      {/* Set the meta title and description using Helmet to override the default */}
      <Helmet>
        <title>Enkeltrick | Enkel-Trick | Enkeltrickbetrug vorbeugen</title>
        <meta
          name="description"
          content="Informiere und schütze dich vor Betrugsversuchen mit Enkel-Trick.de"
        />
      </Helmet>

      {/* Content */}
      <img src="/Fotos/Enkeltrick.webp" alt="Enkeltrick" className="top-image" />

      {/* Share Buttons Section with Icons */}
      <div className="share-buttons">
        <FacebookShareButton url={currentUrl}>
          <FaFacebook size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#4267B2' }} />
        </FacebookShareButton>

        <WhatsappShareButton url={currentUrl}>
          <FaWhatsapp size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#25D366' }} />
        </WhatsappShareButton>

        {/* Using TwitterShareButton with XIcon for X platform */}
        <TwitterShareButton url={currentUrl}>
          <XIcon size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#1DA1F2' }} />
        </TwitterShareButton>

        <LinkedinShareButton url={currentUrl}>
          <FaLinkedin size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#0077b5' }} />
        </LinkedinShareButton>

        <EmailShareButton url={currentUrl} subject="Check this out!">
          <FaEnvelope size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#D44638' }} />
        </EmailShareButton>
      </div>

      <h1>Der sogenannte Enkeltrick</h1>

      <p>
        Der Enkeltrick gehört zu den skrupellosesten Methoden des Telefonbetrugs und zielt hauptsächlich auf ältere, oft alleinlebende Menschen ab.
        Die Täter nutzen den emotionalen Bezug und die Fürsorge, die viele Ältere gegenüber ihren Enkeln und Verwandten empfinden, um hohe Geldsummen
        zu ergaunern. Da der Enkeltrickbetrug für die Opfer schwerwiegende finanzielle und emotionale Folgen haben kann, ist es wichtig, die Merkmale
        dieser Betrugsmasche zu kennen und sich präventiv zu schützen.
      </p>

      <h2>Was ist der Enkeltrick?</h2>
      <p>
        Der Enkeltrick beginnt meist mit einem Anruf, bei dem die Betrüger sich als ein naher Verwandter – oft Enkel, Neffe oder Bekannter – ausgeben.
        Sie beginnen oft mit einer Frage wie: „Rate mal, wer hier spricht?“ oder geben vor, in einer Notsituation zu sein. Die Gründe variieren, können
        aber eine Autoreparatur, eine dringende Anschaffung oder eine Notlage nach einem Unfall umfassen. Durch die Forderung nach einer schnellen
        finanziellen Hilfe versetzen die Täter ihre Opfer gezielt in Stress und bauen psychologischen Druck auf, damit die Betroffenen kaum Zeit haben,
        den Anruf zu hinterfragen.
      </p>

      <h2>Wie du den Enkeltrick erkennen und verhindern kannst</h2>
      <p>
        Die Polizei rät, wachsam zu bleiben und einige grundlegende Regeln zu beachten, wenn ein Anrufer sich als Familienmitglied oder Bekannter ausgibt:
      </p>
      <ul>
        <li><strong>Nicht raten, sondern fragen:</strong> Fordere Anrufer immer dazu auf, ihren Namen zu nennen, anstatt die Identität zu raten.</li>
        <li><strong>Wissen prüfen:</strong> Stelle Fragen, die nur ein echter Verwandter beantworten kann.</li>
        <li><strong>Nicht unter Druck setzen lassen:</strong> Betrüger versuchen oft, durch Dringlichkeit ihre Opfer zu einer schnellen Reaktion zu bewegen.</li>
        <li><strong>Verifizierte Rückrufe:</strong> Rufe den vermeintlichen Anrufer unter einer dir bekannten Nummer zurück, um die Situation zu bestätigen.</li>
      </ul>

      <h2>Wie sich der Enkel-Trick weiterentwickelt hat</h2>
      <p>
        In den letzten Jahren ist der Enkel-Trick immer vielfältiger geworden, und die Täter nutzen zunehmend digitale Kanäle. Besonders Messengerdienste
        wie WhatsApp werden vermehrt für Betrugsversuche genutzt. Häufig beginnt der Betrug über WhatsApp mit Nachrichten wie „Hallo Mama, mein Handy
        ist kaputt, das ist meine neue Nummer…“ oder ähnlichen Nachrichten, die auf eine enge Beziehung und Vertrauen abzielen.
      </p>

      <h3>Wie du dich vor WhatsApp-Betrug im Rahmen des Enkeltricks schützt:</h3>
      <ul>
        <li><strong>Vertrauenswürdigkeit prüfen:</strong> Kontaktiere die Person über die bekannte Nummer, bevor du einer neuen Nummer vertraust.</li>
        <li><strong>Geldforderungen hinterfragen:</strong> Lass dich nicht drängen, Überweisungen an neue Konten vorzunehmen, ohne genaue Informationen einzuholen.</li>
      </ul>

      <h2>Polizeiliche Empfehlungen zum Schutz vor dem Enkeltrickbetrug</h2>
      <p>
        Die Polizei stellt klar, dass keine Geldforderungen am Telefon, weder durch die Polizei selbst noch durch andere Behörden oder Familienangehörige,
        je so dringend sind, dass sie keine Zeit für Nachfragen und Rückversicherungen lassen. Die Polizei rät daher, den Enkeltrick im Familien- und
        Freundeskreis zum Thema zu machen und ältere Menschen regelmäßig zu sensibilisieren. Eine wichtige Sicherheitsmaßnahme ist auch, den Eintrag im
        Telefonbuch zu anonymisieren, da Betrüger oft gezielt nach älteren Vornamen oder kurzen Nummern suchen.
      </p>

      <h2>Unterstützung beim Schutz vor dem Enkeltrick durch den Weißen Ring</h2>
      <p>
        Opfer von Betrug, einschließlich des Enkel-Tricks, können beim 
        <a 
          href="https://weisser-ring.de/betrugsmaschen" 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ fontWeight: 'bold', textDecoration: 'none', color: '#6A0DAD' }}
        >
          Weißer Ring
        </a>
        Unterstützung finden. Diese Organisation hilft nicht nur mit Rat und Tat, sondern bietet auch Informationen, wie du dich besser schützen kannst. Mehr Informationen zu den Angeboten des Weißen Rings findest du auf der Webseite.
      </p>

      <h2>Fazit: Vorsicht beim Enkeltrick</h2>
      <p>
        Der Enkeltrick und ähnliche Betrugsmaschen sind perfide Methoden, die besonders auf ältere Menschen abzielen. Durch gezielte Vorsichtsmaßnahmen und
        die richtige Reaktion auf verdächtige Anrufe oder Nachrichten kannst du dich effektiv schützen. Wichtig ist, dich nicht unter Druck setzen zu lassen,
        Rücksprache mit vertrauten Personen zu halten und bei Verdachtsfällen sofort die Polizei zu verständigen.
      </p>

      {/* Weitere hilfreiche Links auf Enkel-Trick.de */}
      <h2>Weitere hilfreiche Links auf Enkel-Trick.de</h2>
      <ul>
        <li><a href="https://enkel-trick.de/blog/Schockanruf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schockanruf und Betrug durch falsche Notrufe</a></li>
        <li><a href="https://enkel-trick.de/blog/weitere-betrugsarten" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Weitere Betrugsarten und Prävention</a></li>
        <li><a href="https://enkel-trick.de/blog/schutz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schutzmaßnahmen gegen Betrug</a></li>
      </ul>

      {/* Hilfreiche Links Sektion */}
      <h2>Hilfreiche Links zum Thema Enkeltrickbetrug</h2>
      <ul>
        <li><a href="https://www.bmi.bund.de/SharedDocs/schwerpunkte/DE/enkeltrick/artikel-enkeltrick.html" target="_blank" rel="noopener noreferrer">BMI: Enkeltrick und Vorsichtstipps</a></li>
        <li><a href="https://polizei.nrw/artikel/der-enkeltrick" target="_blank" rel="noopener noreferrer">Polizei NRW: Der Enkeltrick</a></li>
        <li><a href="https://weisser-ring.de/betrugsmaschen" target="_blank" rel="noopener noreferrer">Weißer Ring: Betrugsmaschen</a></li>
        <li><a href="https://www.polizei-beratung.de/themen-und-tipps/betrug/enkeltrick/" target="_blank" rel="noopener noreferrer">Polizei-Beratung: Enkeltrick</a></li>
      </ul>
    </div>
  );
}

export default Blog;
