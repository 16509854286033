// Blog.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton, // TwitterShareButton for X functionality
  LinkedinShareButton,
  EmailShareButton
} from 'react-share';
import { FaFacebook, FaWhatsapp, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { XIcon } from 'react-share'; // Importing XIcon for the X platform logo
import '../index.css';

function Blog() {
  const currentUrl = window.location.href;

  return (
    <div className="blog-container">
      {/* Set the meta title and description */}
      <Helmet>
        <title>Falscher Polizeibeamter | WhatsApp Betrug | Gewinnversprechen</title>
        <meta
          name="description"
          content="Informiere und schütze dich vor Betrugsversuchen mit Enkel-Trick.de"
        />
      </Helmet>
     
      {/* Sonstiges Bild am Anfang der Seite */}
      <img src="/Fotos/Sonstiges.webp" alt="Sonstiges" className="top-image" />

      {/* Share Buttons Section with Icons */}
      <div className="share-buttons">
        <FacebookShareButton url={currentUrl}>
          <FaFacebook size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#4267B2' }} />
        </FacebookShareButton>

        <WhatsappShareButton url={currentUrl}>
          <FaWhatsapp size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#25D366' }} />
        </WhatsappShareButton>

        {/* Using TwitterShareButton with XIcon for X platform */}
        <TwitterShareButton url={currentUrl}>
          <XIcon size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#1DA1F2' }} />
        </TwitterShareButton>

        <LinkedinShareButton url={currentUrl}>
          <FaLinkedin size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#0077b5' }} />
        </LinkedinShareButton>

        <EmailShareButton url={currentUrl} subject="Check this out!">
          <FaEnvelope size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#D44638' }} />
        </EmailShareButton>
      </div>

      <h1>Falsche Polizeibeamte, Phishing und falsche Gewinnversprechen</h1>

      <p>
        In den letzten Jahren haben sich die Methoden der Betrüger stetig weiterentwickelt, um immer neue Wege zu finden, ahnungslose Menschen um ihr
        Geld und ihre persönlichen Daten zu bringen. Besonders ältere Menschen stehen im Visier der Täter, da sie häufig als besonders vertrauensselig
        und hilfsbereit wahrgenommen werden. Hier ein Überblick über häufige Betrugsmaschen und wie du dich effektiv schützen kannst.
      </p>

      <h2>Falsche Polizisten am Telefon</h2>
      <p>
        Eine weit verbreitete Betrugsmasche ist der sogenannte „falsche Polizist“. Dabei geben sich Betrüger am Telefon als Polizisten aus und nutzen
        verschiedene Geschichten, um das Vertrauen ihrer Opfer zu gewinnen. Oft behaupten sie, dass das Vermögen des Opfers durch Einbrecher bedroht
        sei oder dass Schwarzgeld auf den Konten gefunden wurde. Um das vermeintliche Geld „zu schützen“, fordern sie die Opfer auf, Bargeld und
        Wertsachen an einen vermeintlichen Polizeibeamten zu übergeben, der es sicher verwahren wird.
      </p>

      <h3>Tipps zur Vorbeugung</h3>
      <ul>
        <li>Vertraue niemals Anrufern, die sich als Polizisten ausgeben und Geld verlangen.</li>
        <li>Nimm dir Zeit, um die Echtheit des Anrufs zu überprüfen, und ruf deine örtliche Polizeidienststelle unter der offiziellen Nummer zurück.</li>
        <li>Übergib niemals Bargeld oder Wertsachen an Fremde – echte Polizisten würden dies niemals verlangen.</li>
      </ul>

      <h2>Phishing per SMS: Vorsicht bei Links und unbekannten Absendern</h2>
      <p>
        Eine weitere gängige Betrugsmasche ist Phishing per SMS. Hierbei senden Betrüger täuschend echte Nachrichten im Namen bekannter Firmen wie
        Paketdienste oder Banken, die einen wichtigen Link enthalten, auf den das Opfer klicken soll. Diese Links führen jedoch zu betrügerischen
        Seiten, auf denen Kriminelle versuchen, an persönliche Daten, Bankinformationen oder sogar Zugang zu deinem Mobilgerät zu gelangen. Besonders
        gefährlich sind solche Nachrichten, wenn sie eine Schadsoftware auf deinem Handy installieren.
      </p>

      <h3>Schutz vor Phishing per SMS</h3>
      <ul>
        <li>Klicke nie auf Links in Nachrichten von unbekannten Absendern und lösche die SMS umgehend.</li>
        <li>Solltest du auf einen Link geklickt haben, setze dein Handy in den Flugmodus und informiere deinen Mobilfunkanbieter.</li>
        <li>Rufe die Firma oder Bank an und frage nach, falls du unsicher bist, ob die SMS echt ist.</li>
      </ul>

      <h2>Kaffeefahrten: Vorsicht vor Verkaufsveranstaltungen</h2>
      <p>
        Kaffeefahrten sind eine klassische Betrugsmasche, bei der meist ältere Menschen zu vermeintlich preiswerten Ausflugsfahrten eingeladen werden.
        Vor Ort entpuppen sich diese Fahrten jedoch als Verkaufsveranstaltungen, bei denen die Teilnehmer massiv unter Druck gesetzt werden, überteuerte
        Produkte zu kaufen. Häufig handelt es sich dabei um minderwertige Waren, die zu weit überhöhten Preisen angeboten werden.
      </p>

      <h3>Tipps zur Prävention bei Kaffeefahrten</h3>
      <ul>
        <li>Kaufe nichts auf Verkaufsfahrten und unterschreibe keine Verträge.</li>
        <li>Verträge, die bei Kaffeefahrten geschlossen werden, können in der Regel innerhalb von 14 Tagen widerrufen werden. Nutze dazu ein Einschreiben mit Rückschein.</li>
        <li>Lasse dich nicht einschüchtern, wenn der Veranstalter Druck ausübt – du hast das Recht, jederzeit zu gehen.</li>
      </ul>

      <h2>Gewinnversprechen: Misstrauen ist der beste Schutz</h2>
      <p>
        Betrüger nutzen oft falsche Gewinnversprechen, um ihre Opfer in die Falle zu locken. Sie melden sich per Telefon, E-Mail oder Brief und behaupten,
        dass das Opfer einen großen Gewinn gemacht habe. Doch um den Gewinn zu erhalten, müsse zunächst eine Gebühr gezahlt werden. Seriöse Gewinnspiele
        verlangen niemals eine Vorauszahlung – das ist ein klares Zeichen für Betrug.
      </p>

      <h3>Schutz vor falschen Gewinnversprechen</h3>
      <ul>
        <li>Frage nach, ob du tatsächlich an einem Gewinnspiel teilgenommen hast – wenn nicht, ist es wahrscheinlich ein Betrug.</li>
        <li>Gib niemals persönliche Informationen wie Bankdaten oder Adressen heraus.</li>
        <li>Lass dich nicht von der Aussicht auf einen Gewinn dazu verleiten, Geld zu überweisen oder kostenpflichtige Nummern anzurufen.</li>
      </ul>

      <h2>Wie du dich allgemein vor Betrug schützt</h2>
      <p>
        Der beste Schutz vor Betrug ist eine gesunde Portion Misstrauen. Betrüger setzen auf psychologischen Druck und Vertrauen, um ihre Opfer zu
        manipulieren. Sprich mit deinen Familienmitgliedern und Freunden über diese Maschen und informiere sie über die aktuellen Methoden der Betrüger.
      </p>

      <h3>Allgemeine Sicherheitstipps</h3>
      <ul>
        <li>Gib niemals finanzielle Details oder persönliche Informationen am Telefon weiter, wenn du den Anrufer nicht kennst.</li>
        <li>Nutze offizielle Kanäle und rufe Institutionen über bekannte Nummern zurück, um die Echtheit eines Anrufs oder einer Nachricht zu überprüfen.</li>
        <li>Informiere die Polizei, wenn du Opfer eines Betrugs geworden bist oder einen verdächtigen Anruf erhalten hast.</li>
      </ul>

      <h2>Unterstützung beim Weißen Ring</h2>
      <p>
        Wenn du Opfer eines Betrugs geworden bist, kannst du dich an den{' '}
        <a
            href="https://weisser-ring.de/betrugsmaschen"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: 'bold', textDecoration: 'none', color: '#6A0DAD' }}
        >
            Weißen Ring
        </a>{' '}
        wenden. Diese Organisation bietet Rat und Hilfe für Opfer und zeigt dir, wie du dich in Zukunft besser schützen kannst.
      </p>

      {/* Weitere hilfreiche Links auf Enkel-Trick.de */}
      <h2>Weitere hilfreiche Links auf Enkel-Trick.de</h2>
      <ul>
        <li><a href="https://enkel-trick.de/blog/Enkeltrick" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Der sogenannte Enkeltrick</a></li>
        <li><a href="https://enkel-trick.de/blog/Schockanruf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schockanruf und Betrug durch falsche Notrufe</a></li>
        <li><a href="https://enkel-trick.de/blog/schutz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schutzmaßnahmen gegen Betrug</a></li>
      </ul>
     
      <h2>Hilfreiche Links</h2>
      <ul>
        <li><a href="https://www.bmi.bund.de/SharedDocs/schwerpunkte/DE/enkeltrick/artikel-enkeltrick.html" target="_blank" rel="noopener noreferrer">BMI: Enkeltrick und Vorsichtstipps</a></li>
        <li><a href="https://polizei.nrw/artikel/betrueger-geben-sich-am-telefon-als-polizeibeamte-aus" target="_blank" rel="noopener noreferrer">Polizei NRW: Betrüger als falsche Polizisten</a></li>
        <li><a href="https://weisser-ring.de/betrugsmaschen" target="_blank" rel="noopener noreferrer">Weißer Ring: Betrugsmaschen</a></li>
        <li><a href="https://www.polizei-beratung.de/themen-und-tipps/betrug/betrug-durch-falsche-polizisten/" target="_blank" rel="noopener noreferrer">Mehr Informationen zu Betrug durch falsche Polizisten</a></li>
      </ul>
    </div>
  );
}

export default Blog;
