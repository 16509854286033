import React from 'react';
import { Helmet } from 'react-helmet';
import '../index.css'; // Ensure this path points to your CSS file

function About() {
  return (
    <div className="about-container">
      <Helmet>
        <title>Über Uns - Enkel-Trick.de</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über die Mission und Vision von Enkel-Trick.de, einer Plattform zum Schutz vor Betrugsversuchen und als Frühwarnsystem für Trickbetrug in Deutschland."
        />
      </Helmet>

      <h1>Über Enkel-Trick.de</h1>

      <p>
        <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" className="bold-link">Enkel-Trick.de</a> ist eine Plattform, die als Frühwarnsystem für Betrugsversuche dient. Unser Ziel ist es, Menschen besser vor den immer raffinierteren Betrugsmaschen zu schützen. Betrüger entwickeln ihre Methoden ständig weiter und wir möchten dabei helfen, dass jeder über diese Gefahren informiert ist. Auf unserer interaktiven Karte werden aktuelle Betrugsfälle in ganz Deutschland angezeigt, sodass die Nutzer rechtzeitig gewarnt und vorbereitet sind.
      </p>

      <p>
        Die Idee zu <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" className="bold-link">Enkel-Trick.de</a> entstand aus der Community von <a href="https://gogaudi.de/" target="_blank" rel="noopener noreferrer" className="bold-link">GoGaudi</a>. Viele unserer Mitglieder berichteten von Betrugsversuchen, die sie selbst oder ihre Angehörigen erlebt haben. Diese Rückmeldungen haben uns dazu motiviert, <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" className="bold-link">Enkel-Trick.de</a> ins Leben zu rufen.
      </p>

      <p>
        Mit <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" className="bold-link">Enkel-Trick.de</a> wollen wir dafür sorgen, dass Menschen sicherer leben können, indem sie frühzeitig über Betrugsversuche informiert werden. Wir setzen auf Transparenz und bieten Zugang zu den neuesten Entwicklungen, um das Risiko, Opfer von Trickbetrügern zu werden, zu minimieren.
      </p>

      <p>
        Für Verbesserungsvorschläge oder Rückfragen sind wir gerne unter <a href="mailto:info@gogaudi.de" className="bold-link">info@gogaudi.de</a> zu erreichen. Wir freuen uns auf Dein Feedback!
      </p>

      <p>&nbsp;</p> {/* Empty paragraph for spacing */}
      <p>&nbsp;</p> {/* Empty paragraph for spacing */}

      {/* Bottom images side by side */}
      <div className="image-row">
        <img src="/Fotos/aboutUs1.webp" alt="Enkel-Trick.de Story" className="bottom-image" />
        <img src="/Fotos/aboutUs2.webp" alt="Enkel-Trick.de Vision" className="bottom-image" />
      </div>
    </div>
  );
}

export default About;
