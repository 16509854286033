import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import { Link } from 'react-router-dom';
import '../index.css'; // Ensure you have an index.css file for global styles

function Blog() {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen width is mobile size
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen to resize events
    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  const outerContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    paddingTop: isMobile ? '80px' : '20px', // Increased top padding on mobile for space with header
    paddingBottom: '20px',
    boxSizing: 'border-box',
    width: '100%',
  };

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '40px',
    maxWidth: '1200px',
  };

  const cardStyle = {
    width: '100%',
    maxWidth: isMobile ? '300px' : '450px', // Smaller width on mobile
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  };

  const baseImageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '12px',
    transition: 'transform 0.3s ease, filter 0.3s ease',
  };

  const hoverImageStyle = {
    ...baseImageStyle,
    filter: 'brightness(0.85)',
    transform: 'scale(1.02)',
  };

  const [hovered, setHovered] = useState(null);

  return (
    <div style={outerContainerStyle}>
      <Helmet>
        <title>Blog - Enkeltrick & Betrugsprävention</title>
        <meta
          name="description"
          content="Lerne mehr über den Enkeltrick, Schockanrufe und andere Betrugsarten sowie Schutzmaßnahmen auf unserem Blog."
        />
      </Helmet>
      
      <div style={containerStyle}>
        {/* Card for Enkeltrick */}
        <div style={cardStyle}>
          <Link to="/blog/enkeltrick">
            <img
              src="/Fotos/Enkeltrick.webp"
              alt="Enkeltrick"
              style={hovered === 'enkeltrick' ? hoverImageStyle : baseImageStyle}
              onMouseEnter={() => setHovered('enkeltrick')}
              onMouseLeave={() => setHovered(null)}
            />
          </Link>
        </div>

        {/* Card for Schockanruf */}
        <div style={cardStyle}>
          <Link to="/blog/schockanruf">
            <img
              src="/Fotos/Schockanruf.webp"
              alt="Schockanruf"
              style={hovered === 'schockanruf' ? hoverImageStyle : baseImageStyle}
              onMouseEnter={() => setHovered('schockanruf')}
              onMouseLeave={() => setHovered(null)}
            />
          </Link>
        </div>

        {/* Card for Weitere Betrugsarten */}
        <div style={cardStyle}>
          <Link to="/blog/weitere-betrugsarten">
            <img
              src="/Fotos/Sonstiges.webp"
              alt="Weitere Betrugsarten"
              style={hovered === 'weitere-betrugsarten' ? hoverImageStyle : baseImageStyle}
              onMouseEnter={() => setHovered('weitere-betrugsarten')}
              onMouseLeave={() => setHovered(null)}
            />
          </Link>
        </div>

        {/* Card for Schutz */}
        <div style={cardStyle}>
          <Link to="/blog/schutz">
            <img
              src="/Fotos/Tipps.webp"
              alt="Schutz"
              style={hovered === 'schutz' ? hoverImageStyle : baseImageStyle}
              onMouseEnter={() => setHovered('schutz')}
              onMouseLeave={() => setHovered(null)}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Blog;
