// Blog.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton, // TwitterShareButton for X functionality
  LinkedinShareButton,
  EmailShareButton
} from 'react-share';
import { FaFacebook, FaWhatsapp, FaLinkedin, FaEnvelope } from 'react-icons/fa'; // Import other icons from react-icons/fa
import { XIcon } from 'react-share'; // Importing XIcon for the X platform logo
import '../index.css'; // Ensure you have an index.css file for global styles

function Blog() {
  const currentUrl = window.location.href;

  return (
    <div className="blog-container">
      {/* Set the meta title and description using Helmet to override the default */}
      <Helmet>
        <title>Schockanruf | Schockanrufe | Telefonbetrug vorbeugen</title>
        <meta
          name="description"
          content="Informiere und schütze dich vor Betrugsversuchen mit Enkel-Trick.de"
        />
      </Helmet>

      {/* Schockanruf Bild am Anfang der Seite */}
      <img src="/Fotos/Schockanruf.webp" alt="Schockanruf" className="top-image" />

      {/* Share Buttons Section with Icons */}
      <div className="share-buttons">
        <FacebookShareButton url={currentUrl}>
          <FaFacebook size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#4267B2' }} />
        </FacebookShareButton>

        <WhatsappShareButton url={currentUrl}>
          <FaWhatsapp size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#25D366' }} />
        </WhatsappShareButton>

        {/* Using TwitterShareButton with XIcon for X platform */}
        <TwitterShareButton url={currentUrl}>
          <XIcon size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#1DA1F2' }} />
        </TwitterShareButton>

        <LinkedinShareButton url={currentUrl}>
          <FaLinkedin size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#0077b5' }} />
        </LinkedinShareButton>

        <EmailShareButton url={currentUrl} subject="Check this out!">
          <FaEnvelope size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#D44638' }} />
        </EmailShareButton>
      </div>

      <h1>Schockanruf: Die gefährliche Betrugsmasche am Telefon</h1>

      <p>
        Schockanrufe gehören zu den perfidesten Betrugsmaschen, die derzeit immer häufiger vorkommen. Ziel dieser Methode ist es, die Opfer durch
        eine dramatische und erschreckende Geschichte so zu schockieren, dass sie in Panik versetzt werden und ihre Entscheidungen nicht rational
        abwägen können. Durch die Kombination aus emotionalem Druck und der Glaubwürdigkeit einer akuten Notlage wird den Angerufenen eine schnelle
        Reaktion abverlangt, die oft zu erheblichen finanziellen Verlusten führt.
      </p>

      <h2>Wie funktioniert ein Schockanruf?</h2>
      <p>
        Ein Schockanruf beginnt meist mit einem Anruf von einer unbekannten Nummer. Der oder die Anrufende gibt sich als nahestehendes Familienmitglied,
        oft ein Sohn oder Enkelkind, aus und schildert eine dramatische Situation: Ein schwerer Verkehrsunfall, eine lebensbedrohliche Erkrankung oder
        ein anderer Notfall, der sofortiges Handeln erfordert. Um der vermeintlichen Strafe oder Behandlungskosten zu entgehen, wird eine hohe Geldsumme
        gefordert, die entweder per Übergabe oder durch Überweisung gezahlt werden soll.
      </p>

      <p>
        Häufig nehmen die Betrüger dabei die Identität von Ärzten, Polizisten oder Anwälten an, um die Glaubwürdigkeit ihrer Geschichte zu verstärken.
        Durch den vermeintlichen Bezug zu einer anerkannten Autoritätsperson – sei es ein Arzt, ein Polizeibeamter oder ein Anwalt – soll das Vertrauen
        der Opfer weiter gestärkt und gleichzeitig deren Zweifel unterdrückt werden.
      </p>

      <h2>Die Tricks und Varianten der Betrüger</h2>
      <p>
        Die Schockanruf-Betrüger nutzen verschiedene Szenarien, die alle auf dieselbe psychologische Wirkung abzielen: Die Betroffenen sollen in Angst
        und Sorge um ihre Angehörigen versetzt werden, sodass sie ohne weitere Überlegungen auf die Forderungen eingehen. Hier sind einige häufige Varianten:
      </p>

      <h3>Verkehrsunfall mit schweren Folgen</h3>
      <p>
        Der Anrufende gibt sich als Familienmitglied aus, das einen Verkehrsunfall verursacht habe, bei dem andere Personen schwer verletzt wurden. Oft
        wird behauptet, dass zur Abwendung einer Gefängnisstrafe eine sofortige Kaution gezahlt werden müsse. Mit einer weinerlichen, verzweifelten
        Stimme wird die Schwere der Situation verdeutlicht, um das Opfer zu schnellem Handeln zu drängen.
      </p>

      <h3>Schwere Erkrankung oder Verletzung</h3>
      <p>
        Ein Anrufer gibt sich als Arzt oder Krankenhausmitarbeiter aus und teilt mit, dass ein Familienmitglied schwer verletzt oder lebensbedrohlich
        erkrankt sei. Zur Rettung müsse jedoch umgehend ein teures Medikament beschafft werden, wofür eine hohe Geldsumme erforderlich sei.
      </p>

      <h3>Bedrohung durch Einbrecher</h3>
      <p>
        In anderen Fällen gibt sich der Anrufer als Polizist aus, der vor Einbrechern in der Nähe warnt. Zur Sicherheit der Wertsachen und des
        Bargelds wird angeboten, diese sicherheitshalber „vorübergehend“ in Gewahrsam zu nehmen.
      </p>

      <p>
        Diese Szenarien variieren, doch allen gemein ist, dass die Betrüger eine hohe Summe Geldes verlangen und das Opfer permanent unter Druck setzen,
        um eine schnelle, unüberlegte Reaktion hervorzurufen.
      </p>

      <h2>Erkennen von Schockanrufen</h2>
      <p>
        Schockanrufe sind darauf ausgelegt, rationales Denken zu verhindern und die Opfer emotional zu überlasten. Daher gibt es einige Merkmale, an
        denen man diese Art des Telefonbetrugs erkennen kann:
      </p>
      <ul>
        <li>
          <strong>Schockierende Nachricht + Geldforderung:</strong> Fast immer geht die schockierende Nachricht mit einer finanziellen Forderung einher,
          die sofort erfüllt werden soll.
        </li>
        <li>
          <strong>Aufbau von Druck und Stress:</strong> Die Anrufer setzen das Opfer zeitlich massiv unter Druck, häufig begleitet von wiederholten
          Anrufen oder einer emotional aufgeladenen, weinerlichen Stimme.
        </li>
        <li>
          <strong>Wissensvorsprung über persönliche Details:</strong> Durch die heutige Social-Media-Welt ist es für Betrüger leichter denn je, persönliche
          Informationen zu beschaffen. Lass dich daher nicht von vermeintlich „privaten“ Informationen täuschen, die der Anrufer möglicherweise kennt.
        </li>
        <li>
          <strong>Falsche Amtspersonen:</strong> Die Polizei oder ähnliche Institutionen würden niemals telefonisch nach Geld oder Wertgegenständen verlangen.
        </li>
      </ul>

      <h2>Tipps und Verhaltensregeln vom Bundeskriminalamt</h2>
      <p>
        Das Bundeskriminalamt (BKA) rät dringend dazu, solche Anrufe schnellstmöglich zu beenden und nicht auf die Forderungen einzugehen. Es ist wichtig,
        keine persönlichen oder finanziellen Details preiszugeben. Falls Unsicherheiten bestehen, sollte das betroffene Familienmitglied über eine
        bekannte Telefonnummer kontaktiert werden. Hier einige zusätzliche Empfehlungen:
      </p>
      <ul>
        <li>Legen Sie auf und lassen Sie sich nicht unter Druck setzen</li>
        <li>Übergeben Sie niemals Geld oder Wertsachen an unbekannte Personen</li>
        <li>Kontaktieren Sie die örtliche Polizeidienststelle, falls ein verdächtiger Anruf stattgefunden hat</li>
        <li>Holen Sie sich Unterstützung bei Opferberatungsstellen wie dem <a href="https://weisser-ring.de/Telefonbetrug" target="_blank" rel="noopener noreferrer">Weißen Ring</a></li>
      </ul>

      <p>
        Schockanrufe sind nicht nur ein wachsendes Problem, sondern auch eine erhebliche Belastung für die Betroffenen. Durch gezielte Aufklärung und
        Prävention kann jedoch vielen potenziellen Opfern geholfen werden, sich gegen diesen Betrug zu wehren.
      </p>

     
    {/* Weitere hilfreiche Links auf Enkel-Trick.de */}
    <h2>Weitere hilfreiche Links auf Enkel-Trick.de</h2>
      <ul>
        <li><a href="https://enkel-trick.de/blog/Enkeltrick" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Der sogenannte Enkeltrick</a></li>
        <li><a href="https://enkel-trick.de/blog/weitere-betrugsarten" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Weitere Betrugsarten und Prävention</a></li>
        <li><a href="https://enkel-trick.de/blog/schutz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schutzmaßnahmen gegen Betrug</a></li>
      </ul>
     
      {/* Hilfreiche Links Sektion */}
      <h2>Hilfreiche Links</h2>
      <ul>
        <li><a href="https://www.bka.de/SharedDocs/Kurzmeldungen/DE/Warnhinweise/230524_Schockanrufe.html" target="_blank" rel="noopener noreferrer">BKA Warnung zu Schockanrufen</a></li>
        <li><a href="https://www.polizei.sachsen.de/de/101409.htm" target="_blank" rel="noopener noreferrer">Polizei Sachsen zu Schockanrufen</a></li>
        <li><a href="https://koeln.polizei.nrw/artikel/schockanruf" target="_blank" rel="noopener noreferrer">Polizei Köln: Infos zu Schockanrufen</a></li>
        <li><a href="https://weisser-ring.de/Telefonbetrug" target="_blank" rel="noopener noreferrer">Weißer Ring: Telefonbetrug</a></li>
      </ul>
    </div>
  );
}

export default Blog;
