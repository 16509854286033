// Blog.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton, // TwitterShareButton for X functionality
  LinkedinShareButton,
  EmailShareButton
} from 'react-share';
import { FaFacebook, FaWhatsapp, FaLinkedin, FaEnvelope } from 'react-icons/fa'; // Import other icons from react-icons/fa
import { XIcon } from 'react-share'; // Importing XIcon for the X platform logo
import '../index.css';

function Blog() {
  const currentUrl = window.location.href;

  return (
    <div className="blog-container">
      {/* Set the meta title and description using Helmet to override the default */}
      <Helmet>
        <title>Schutz vor Enkeltrick und Betrug</title>
        <meta
          name="description"
          content="Informiere und schütze dich vor Betrugsversuchen mit Enkel-Trick.de"
        />
      </Helmet>
      
      {/* Image at the top of the page */}
      <img src="/Fotos/Tipps.webp" alt="Tipps zum Schutz vor Betrug" className="top-image" />

      {/* Share Buttons Section with Icons */}
      <div className="share-buttons">
        <FacebookShareButton url={currentUrl}>
          <FaFacebook size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#4267B2' }} />
        </FacebookShareButton>

        <WhatsappShareButton url={currentUrl}>
          <FaWhatsapp size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#25D366' }} />
        </WhatsappShareButton>

        {/* Using TwitterShareButton with XIcon for X platform */}
        <TwitterShareButton url={currentUrl}>
          <XIcon size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#1DA1F2' }} />
        </TwitterShareButton>

        <LinkedinShareButton url={currentUrl}>
          <FaLinkedin size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#0077b5' }} />
        </LinkedinShareButton>

        <EmailShareButton url={currentUrl} subject="Check this out!">
          <FaEnvelope size={32} style={{ margin: '0 10px', cursor: 'pointer', color: '#D44638' }} />
        </EmailShareButton>
      </div>

      <h1>So kannst du dich schützen vor dem Enkeltrick</h1>

      <p>
        Der sogenannte Enkeltrick ist eine besonders heimtückische Masche, die vor allem auf ältere Menschen abzielt. Betrüger geben sich als Verwandte aus und versuchen, durch erfundene Notfälle Geld von ihren Opfern zu erhalten. Damit du gut informiert bist und dich effektiv schützen kannst, erklären wir dir hier, wie der Enkeltrick funktioniert, welche Schutzmaßnahmen es gibt und wie du im Ernstfall handeln solltest.
      </p>

      <h2>Wie funktioniert der Enkeltrick?</h2>
      <p>
        Beim Enkeltrick rufen Betrüger an und geben sich als Enkel, Neffe oder andere nahe Verwandte aus. Meist beginnen sie das Gespräch mit einer Frage wie „Rate mal, wer hier ist?“ – so hoffen sie, dass du den Namen eines Angehörigen nennst und ihnen damit die Rolle vorgibst, die sie spielen können. Im Gespräch erzählen sie dann von einem Notfall, wie einem Autounfall oder einer dringenden Geldschuld, und bitten um finanzielle Hilfe. Durch geschickte Gesprächsführung und psychologischen Druck gelingt es ihnen, das Vertrauen ihrer Opfer zu gewinnen und sie zur Herausgabe hoher Geldsummen zu überreden.
      </p>

      <h2>Enkel-Trick.de als Frühwarnsystem</h2>
      <p>
        Eine wertvolle Ressource im Kampf gegen den Enkeltrick ist die Plattform <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#6A0DAD' }}>Enkel-Trick.de</a>. Diese Webseite dient als Frühwarnsystem, indem aktuelle Betrugsversuche und Fälle auf einer interaktiven Karte angezeigt werden. So kannst du jederzeit sehen, wo in Deutschland gerade besonders viele Betrugsversuche gemeldet werden, und dich sowie deine Angehörigen gezielt vorwarnen. Das Ziel von <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#6A0DAD' }}>Enkel-Trick.de</a> ist es, Menschen über neue und immer raffiniertere Betrugsmaschen zu informieren und dabei zu helfen, sich und ihre Angehörigen zu schützen.
      </p>

      <h2>Tipps und Maßnahmen zum Schutz vor dem Enkeltrick</h2>
      <ul>
        <li><strong>Sei wachsam bei Anrufen von „Verwandten“</strong>: Wenn sich jemand am Telefon als Verwandter oder Bekannter ausgibt, stelle ihm Fragen, die nur die echte Person beantworten kann.</li>
        <li><strong>Vermeide Informationen preiszugeben</strong>: Verrate keine Details über deine finanziellen oder familiären Verhältnisse.</li>
        <li><strong>Lass dich nicht unter Druck setzen</strong>: Betrüger versuchen oft, dich zur schnellen Entscheidung zu drängen. Nimm dir die Zeit, die du brauchst, und lege den Hörer auf, wenn dir etwas verdächtig vorkommt.</li>
        <li><strong>Ruf zurück</strong>: Kontaktiere die Person, die angeblich angerufen hat, über die dir bekannte Telefonnummer, um sicherzustellen, dass es sich tatsächlich um deinen Verwandten handelt.</li>
        <li><strong>Niemals Geld an Unbekannte übergeben</strong>: Wenn eine fremde Person dir Geld übergeben soll, handelt es sich mit hoher Wahrscheinlichkeit um Betrug. Vereinbare keine Geldübergaben an Dritte.</li>
      </ul>

      <h2>Weitere Sicherheitstipps gegen den Enkeltrick und andere Betrugsmaschen</h2>
      <p>
        Betrüger entwickeln ständig neue Methoden, um an Geld zu kommen. Hier sind zusätzliche Tipps, wie du dich schützen kannst:
      </p>
      <ul>
        <li><strong>Telefonbucheintrag ändern</strong>: Betrüger suchen häufig in Telefonbüchern nach älteren Namen und wählen gezielt Personen aus. Ein abgekürzter oder nicht veröffentlichter Telefonbucheintrag kann dich weniger sichtbar machen.</li>
        <li><strong>Echte Polizei und Behörden fordern nie Bargeld per Telefon</strong>: Sei vorsichtig, wenn jemand Geld am Telefon verlangt und sich als Beamter ausgibt.</li>
        <li><strong>Keine Eile bei Geldangelegenheiten</strong>: Überlege gut und beende das Gespräch, wenn du unsicher bist. Informiere dich bei einer vertrauenswürdigen Person.</li>
        <li><strong>Aktuelle Betrugsfälle auf <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', color: '#6A0DAD', textDecoration: 'none' }}>Enkel-Trick.de</a> nachsehen</strong>: Hier erhältst du aktuelle Informationen zu Betrugsversuchen und kannst schnell handeln.</li>
      </ul>

      <h2>Was tun, wenn ein Betrugsversuch vermutet wird?</h2>
      <ul>
        <li><strong>Sofort auflegen</strong>: Der sicherste Weg ist oft, das Gespräch zu beenden.</li>
        <li><strong>Polizei informieren</strong>: Melde den Betrugsversuch bei der Polizei unter der Notrufnummer 110.</li>
        <li><strong>Mögliche Details notieren</strong>: Falls du bereits Informationen preisgegeben hast, schreibe alles Wichtige auf, z. B. Datum, Uhrzeit und Gesprächsinhalt. Dies kann später für die Ermittlungen nützlich sein.</li>
      </ul>

      <h2>So hilft dir Enkel-Trick.de, dich zu schützen</h2>
      <p>
        Die Plattform <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#6A0DAD' }}>Enkel-Trick.de</a> ist nicht nur eine Informationsquelle, sondern bietet dir auch die Möglichkeit, aktuelle Betrugsfälle auf einer Karte einzusehen. Damit kannst du herausfinden, ob gerade in deiner Umgebung vermehrt Betrugsversuche gemeldet werden, und so besser auf Anrufe vorbereitet sein.
      </p>

      <h2>Weitere Betrugsmaschen und Prävention</h2>
      <p>
        Der Enkeltrick ist nur eine von vielen Betrugsmaschen. Betrüger nutzen auch den sogenannten Schockanruf, bei dem sie eine Notfallsituation vortäuschen, oder das Phishing, bei dem sie über gefälschte E-Mails oder SMS an persönliche Daten gelangen wollen. Auf <a href="https://enkel-trick.de/blog/weitere-betrugsarten" target="_blank" rel="noopener noreferrer" style={{ color: '#6A0DAD', textDecoration: 'none' }}>Enkel-Trick.de</a> findest du detaillierte Informationen zu weiteren Betrugsarten und Tipps zur Prävention.
      </p>

      <h2>Fazit: Mit Wissen und Vorsicht sicher bleiben</h2>
      <p>
        Die beste Vorsorge gegen den Enkeltrick und ähnliche Betrugsversuche ist es, informiert und wachsam zu sein. Sprich mit deinen Angehörigen, insbesondere mit älteren Familienmitgliedern, über diese Betrugsmaschen. Verweise sie auf wertvolle Ressourcen wie <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#6A0DAD' }}>Enkel-Trick.de</a> und ermutige sie, bei verdächtigen Anrufen immer Vorsicht walten zu lassen.
      </p>

      {/* Weitere hilfreiche Links auf Enkel-Trick.de */}
      <h2>Weitere hilfreiche Links auf Enkel-Trick.de</h2>
      <ul>
        <li><a href="https://enkel-trick.de/blog/Enkeltrick" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Der sogenannte Enkeltrick</a></li>
        <li><a href="https://enkel-trick.de/blog/Schockanruf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schockanruf und Betrug durch falsche Notrufe</a></li>
        <li><a href="https://enkel-trick.de/blog/weitere-betrugsarten" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Weitere Betrugsarten und Prävention</a></li>
      </ul>
     
      <h2>Wichtige Links zum Thema Enkeltrick und Betrugsschutz</h2>
      <ul>
        <li><a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Enkel-Trick.de: Informationen und Frühwarnsystem</a></li>
        <li><a href="https://www.malteser.de/dabei/familie-freundschaft/enkeltrick-und-co-so-beugen-sie-vor.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Malteser</a></li>
        <li><a href="https://duisburg.polizei.nrw/schutzvorenkeltrick" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Polizei NRW</a></li>
        <li><a href="https://www.bmi.bund.de/SharedDocs/schwerpunkte/DE/enkeltrick/artikel-enkeltrick.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>BMI</a></li>
      </ul>
    </div>
  );
}

export default Blog;
