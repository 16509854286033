import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { MapContainer, GeoJSON, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Define the bounding box for Germany (zoomed out for more visibility)
const germanyBounds = [
  [55.5, 5.5],  // Adjusted slightly more (Top-left corner)
  [46.5, 16.5]  // Adjusted slightly more (Bottom-right corner)
];

function Home() {
  const [geojsonData, setGeojsonData] = useState(null);
  const [pressData, setPressData] = useState([]);
  const [selectedPress, setSelectedPress] = useState(null); // State to store selected press release
  const [showTable, setShowTable] = useState(false); // State to toggle table visibility
  const markerRefs = useRef([]); // Refs to store marker instances

  useEffect(() => {
    // Fetch GeoJSON data for Germany's states
    fetch('/germany-states.json')
      .then((response) => response.json())
      .then((data) => setGeojsonData(data))
      .catch((error) => console.error('Error fetching GeoJSON:', error));

    // Fetch press release data with error handling
    fetch('/tempPoliceData.json')
      .then((response) => response.json())
      .then((data) => {
        setPressData(data); // Store all the data (even with invalid geo-coordinates)
      })
      .catch((error) => {
        console.error('Error fetching press data:', error);
        setPressData([]); // Set an empty array if fetching fails
      });
  }, []);

  // Custom pulsating marker icon
  const pulsatingIcon = L.divIcon({
    className: 'pulsating-marker',
    iconSize: [12, 12],
  });

  // Function to format city names properly
  const capitalizeName = (name) => {
    return name.toLowerCase().replace(/(^|\s)\S/g, letter => letter.toUpperCase());
  };

  // Function to handle hover effects on states (display state names)
  const onEachFeature = (feature, layer) => {
    const stateName = feature.properties.name;
    layer.bindTooltip(stateName, {
      permanent: false,
      direction: 'center',
      className: 'state-tooltip',
    });

    layer.on({
      mouseover: (e) => {
        const layer = e.target;
        layer.setStyle({
          fillOpacity: 0.7,
          fillColor: '#DDCEF5', // Hover color for the state
        });
      },
      mouseout: (e) => {
        const layer = e.target;
        layer.setStyle({
          fillOpacity: 0.8,
          fillColor: '#EAE9EB', // Default fill color
          color: '#BB9DEB', // Default outline color
        });
      }
    });
  };

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  // Get current date from the first pressData entry (if available)
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // Set the date to yesterday
  currentDate = currentDate.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  

  // Filter out entries without valid latitude and longitude
  const validPressData = pressData.filter(release => 
    typeof release.Latitude === 'number' && 
    typeof release.Longitude === 'number' && 
    !isNaN(release.Latitude) && 
    !isNaN(release.Longitude)
  );

  // Centered popup content
  const CenteredPopup = () => {
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === 'Escape') {
          setSelectedPress(null); // Close the popup when the Escape key is pressed
        }
      };

      // Add event listener for Escape key
      document.addEventListener('keydown', handleEsc);

      return () => {
        // Cleanup the event listener on component unmount
        document.removeEventListener('keydown', handleEsc);
      };
    }, []);

    if (!selectedPress) return null;

    return (
      <div className="centered-popup" style={popupStyle}>
        {/* Close 'x' in the top-right corner */}
        <span className="popup-close-x" onClick={() => setSelectedPress(null)}>x</span>
        <strong>{capitalizeName(selectedPress.Location)}</strong><br /><br />
        {truncateText(selectedPress['Final Title'], 50)}<br /><br />
        <span
          onClick={() => window.open(selectedPress.URL, "_blank")}
          style={{ color: '#5B24B2', cursor: 'pointer', textDecoration: 'underline' }}
        >
          Mehr lesen
        </span>
      </div>
    );
  };

  // Style for the centered popup
  const popupStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
    zIndex: 1000,
    width: '220px', // Fix the width of the popup
    height: '170px', // Adjust the height of the popup
    textAlign: 'center',
    overflow: 'hidden', // Hide overflowing content
    textOverflow: 'ellipsis', // Add ellipsis for overflowing text
    whiteSpace: 'nowrap', // Prevent text wrapping
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Schutz vor Enkeltrickbetrug, Schockanrufen & mehr</title>
        <meta
          name="description"
          content="Betrugsmaschen nehmen in Deutschland täglich zu - schütze dich jetzt vor Enkeltrick, Schockanrufen, falschen Polizisten und mehr."
        />
      </Helmet>
      
      <p className="intro-text" style={{ marginTop: '30px', marginBottom: '1px' }}>
        
      </p>

      <h1 className="title" style={{ color: '#5B24B2', marginBottom: '10px', marginTop: '1px' }}>
        Schutz vor Enkeltrick & Co.
      </h1>

      <hr style={{ border: '1px solid lightgrey', marginTop: '10px' }} />

      {/* Paragraph with fraud cases info above the map */}
      <p className="betrugsradar-text" style={{ fontSize: '18px', textAlign: 'center', marginBottom: '5px' }}>
        <strong>Betrugsradar:</strong>   
        <span className="large-number">{validPressData.length}</span> {validPressData.length === 1 ? 'Betrugsfall' : 'Betrugsfälle'}
      </p>
      <p style={{ fontSize: '16px', textAlign: 'center', color: '#4D4D4D', marginTop: '0px' }}>
        ({currentDate})
      </p>

      {/* Map Section */}
      <div className="map-section" style={{ position: 'relative', marginBottom: '40px' }}>
        <MapContainer
          bounds={germanyBounds}
          maxBounds={germanyBounds} // Restrict the map from being panned outside of this area
          maxBoundsViscosity={1.0}  // Add resistance when trying to pan beyond bounds
          style={{ height: '80vh', width: '100%' }}
          zoomControl={true} // Enable zoom control with plus and minus buttons
          dragging={true} // Enable dragging
          scrollWheelZoom={false} // Disable scroll zoom for better touch experience
          doubleClickZoom={false} // Disable double-click zoom
          touchZoom={true} // Enable touch zoom for mobile devices
          whenCreated={(map) => {
            map.fitBounds(germanyBounds, {
              padding: [20, 20],
              maxZoom: 6, // Limit maximum zoom level
            });
          }}
        >
          {geojsonData && (
            <GeoJSON
              data={geojsonData}
              style={{ color: '#BB9DEB', weight: 2, fillOpacity: 0.8, fillColor: '#EAE9EB' }}
              onEachFeature={onEachFeature}
            />
          )}

          {/* Render valid markers only */}
          {validPressData.map((release, index) => (
            <Marker
              key={index}
              position={[release.Latitude, release.Longitude]}
              icon={pulsatingIcon}
              ref={(el) => (markerRefs.current[index] = el)}
              eventHandlers={{
                click: () => {
                  setSelectedPress(release); // Set the clicked press release for the popup
                },
              }}
            />
          ))}
        </MapContainer>
      </div>

      {/* Centered Popup */}
      {selectedPress && <CenteredPopup />}

      {/* First Container */}
      <div className="container-one">
        <hr style={{ border: '1px solid lightgrey', marginTop: '40px' }} />

        {/* h2 for "Menschen schützen" */}
        <h2 style={{ textAlign: 'left', marginTop: '40px' }}>Enkeltrickbetrug, Schockanrufe und falsche Polizeibeamte</h2>
        <p style={{ textAlign: 'left', lineHeight: '1.8', marginBottom: '40px' }}>
          Betrugsmaschen nehmen in Deutschland täglich zu, und viele wissen nicht, wie sie sich effektiv schützen können. Betrüger nutzen oft emotionale Themen, um ihre Opfer in die Falle zu locken.<br /><br />
          Mit <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" className="bold-link">Enkel-Trick.de</a> sagen wir den Trickbetrügern den Kampf an! <br /><br />
          Unsere interaktive Deutschlandkarte bietet dir stets aktuelle Informationen zu Betrugsfällen im ganzen Land – wir hoffen, dass dies als Frühwarnsystem dient.<br /><br />
        </p>
      </div>

      {/* Second Container */}
      <div className="container-two">
        <hr style={{ border: '1px solid lightgrey', marginTop: '40px' }} />

        {/* h2 for "Wie funktioniert diese Seite?" */}
        <h2 style={{ textAlign: 'left' }}>Wie funktioniert diese Seite?</h2>
        <p style={{ textAlign: 'left', lineHeight: '1.8' }}>
          Mit modernster KI-Technologie durchsuchen wir täglich die aktuellsten Polizeimeldungen und erkennen, ob und welche Betrugsmaschen gerade in deiner Region aktiv sind.<br /><br />
          Wir suchen nach Begriffen wie Enkel-Trick, Schockanrufen, Callcenter-Betrug und vielen weiteren Betrugsmaschen.<br /><br />
          <a href="https://enkel-trick.de/" target="_blank" rel="noopener noreferrer" className="bold-link">Enkel-Trick.de</a> ist eine Initiative von <a href="https://gogaudi.de/" target="_blank" rel="noopener noreferrer" className="bold-link">GoGaudi</a>, der Plattform rund um das Thema Rente.
        </p>

  {/* New content */}
  <h3 style={{ textAlign: 'left' }}>Was bedeutet es wenn keine Betrugsfälle angezeigt werden?</h3>
  <p style={{ textAlign: 'left', lineHeight: '1.8' }}>
    Wenn keine Betrugsfälle angezeigt werden, liegt es daran, dass es an diesem Tag keine polizeilichen Meldungen zu unseren Suchbegriffen gab. Dies kommt besonders am Wochenende vor, wie beispielsweise am Samstag oder Sonntag. Das bedeutet jedoch nicht, dass du in dieser Zeit weniger wachsam sein solltest, denn gerade am Wochenende sind Betrüger oft besonders aktiv.
  </p>
</div>


      {/* Third Container */}
      <div className="container-three">
        <hr style={{ border: '1px solid lightgrey', marginTop: '40px' }} />

        {/* h2 for "Wie kannst du dich schützen?" */}
        <h2 style={{ textAlign: 'left', marginTop: '40px' }}>Wie kannst du dich schützen vor dem Enkeltrick?</h2>
        <p style={{ textAlign: 'left', lineHeight: '1.8' }}>
        Um Betrügern keinen Raum zu geben, ist es wichtig, gut informiert und wachsam zu sein. <br /><br />
        Achte darauf, niemals persönliche Informationen am Telefon preiszugeben, vor allem nicht unter Druck. Sprich regelmäßig mit deinen Freunden und Verwandten über typische Betrugsmaschen, damit sie im Ernstfall vorbereitet sind.<br /><br />
        Das wirksamste Mittel gegen Betrug am Telefon oder im Internet ist eine gesunde Portion Skepsis. Bei dem oben genannten Beispiel mit dem Enkeltrick (oder ähnlichen Betrügereien am Telefon) solltest du das Gespräch umgehend beenden, sobald das dringende Geldproblem erwähnt wird. Zeitdruck ist außerdem immer ein rotes Tuch und bei den meisten Betrugsdiebstählen ein wichtiger Faktor für die Kriminellen.<br /><br />
        Du kannst den vermeintlichen Bekannten direkt unter der gewohnten Telefonnummer zurückrufen, um sicherzustellen, dass es sich um die echte Person handelt. Bestehe auch immer auf ein persönliches Treffen – niemals solltest du dich mit einer Stellvertreterin oder einem Stellvertreter treffen, schon gar nicht für eine Geldübergabe.<br /><br />
        Falls es doch passiert ist und du einer verdächtigen Person Geld übergeben hast, versuche dir die Person und Charakteristika ihres Fahrzeugs zu merken und schriftlich festzuhalten. Das ist wichtig für die spätere Ermittlung.<br /><br />
        Nutze außerdem die Unterstützung der <a href="https://www.bmi.bund.de/SharedDocs/schwerpunkte/DE/enkeltrick/artikel-enkeltrick.html" target="_blank" rel="noopener noreferrer" className="bold-link">Polizei</a> und informiere dich über offizielle Präventionsprogramme.<br /><br />
        Auch werden wir regelmäßig unseren <a href="https://enkel-trick.de/blog" target="_blank" rel="noopener noreferrer" className="bold-link" style={{ color: '#6A0DAD', textDecoration: 'none' }}>Blog</a> updaten, mit weiteren Artikeln zu verschiedenen Betrugsmethoden:
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          <li><a href="https://enkel-trick.de/blog/Enkeltrick" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Alles zum Thema Enkeltrick</a></li>
          <li><a href="https://enkel-trick.de/blog/Schockanruf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schockanruf und Betrug durch falsche Notrufe</a></li>
          <li><a href="https://enkel-trick.de/blog/weitere-betrugsarten" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Weitere Betrugsarten und Prävention</a></li>
          <li><a href="https://enkel-trick.de/blog/schutz" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#6A0DAD' }}>Schutzmaßnahmen gegen Betrug</a></li>
        </ul>
        Mit diesen einfachen Maßnahmen kannst du dich und deine Liebsten besser vor Betrügereien schützen!
      </p>

      </div>

      <hr style={{ border: '1px solid lightgrey', marginTop: '40px' }} />

      {/* Explanation for Table */}
      <div style={{ textAlign: 'center', marginTop: '40px' }}>
        {/* h2 for "Aktuelle Betrugsfälle am (Datum)" */}
        <h2>Aktuelle Betrugsfälle am {currentDate}</h2>
        <p style={{ fontSize: '16px', color: '#4D4D4D' }}>
          In der folgenden Tabelle siehst du die aktuellen Betrugsfälle.
        </p>
      </div>

      {/* Toggle Table Button */}
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <button onClick={() => setShowTable(!showTable)} style={{ padding: '10px 20px', backgroundColor: '#5B24B2', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          {showTable ? 'Tabelle verbergen' : 'Tabelle anzeigen'}
        </button>
      </div>

      {/* Table Section (Only visible when showTable is true) */}
      {showTable && (
        <div className="table-section" style={{ textAlign: 'left', marginTop: '20px' }}>
          <h3 style={{ marginBottom: '10px', color: '#5B24B2' }}>
            Aktuelle Betrugsfälle am <span style={{ color: '#5B24B2' }}>{currentDate}</span>
          </h3>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <thead>
              <tr style={{ backgroundColor: '#f2f2f2', textAlign: 'left', color: '#4D4D4D' }}>
                <th style={{ padding: '12px', borderBottom: '1px solid #ddd', color: '#4D4D4D' }}>Stadt</th>
                <th style={{ padding: '12px', borderBottom: '1px solid #ddd', color: '#4D4D4D' }}>Beschreibung</th>
                <th style={{ padding: '12px', borderBottom: '1px solid #ddd', color: '#4D4D4D' }}>Link</th>
              </tr>
            </thead>
            <tbody>
              {pressData.map((release, idx) => (
                <tr key={idx} style={{ color: '#4D4D4D' }}>
                  <td style={{ padding: '12px', borderBottom: '1px solid #ddd', color: '#4D4D4D' }}>{capitalizeName(release.Location)}</td>
                  <td style={{ padding: '12px', borderBottom: '1px solid #ddd', color: '#4D4D4D' }}>
                    {truncateText(release.Description, 100)}
                  </td>
                  <td style={{ padding: '12px', borderBottom: '1px solid #ddd', color: '#4D4D4D' }}>
                    <a href={release.URL} target="_blank" rel="noopener noreferrer" style={{ color: '#5B24B2' }}>
                      Mehr lesen...
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Home;
